.text-align-left {
    text-align: left !important;
}


.ots-details-container {
    display: flex;
    flex-direction: column;
    padding-inline: 1%;
    height: 100%;
    overflow: hidden;
}

.machine-details-table {
    overflow: scroll;
    max-height: calc(100vh - 212px);
}

.checkbox_class input[type="checkbox"] {
    accent-color: #6D1DF0;
}

.search-input {
    padding: 10px 4px;
    border-radius: 8px;
    background-color: #6e1df02a;
}

.flex-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tooltip {
    position: relative;
    display: inline-block;
    width: '100%';
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0%;
    padding: 10px;
    margin-left: -50px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.active-chevron {
    width: 20px;
    height: 20px;
    transform: rotateZ(180deg);
    margin-left: 4px;
}

.inactive-chevron {
    width: 20px;
    height: 20px;
    margin-left: 4px;
}

.clickable {
    cursor: pointer;
    color: #6D1DF0;
    font-weight: 600;
    text-decoration: underline;
}