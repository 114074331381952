.system-detail-sub-header-container{
    padding-block:20px;
}
.system-detail-sub-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0px;
}


.system-detail-sub-header-heading{
    font-size: 28px;
    font-weight: 600;
}

.sub-header-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}
.system-detail-sub-header-button{
    font-size: 18px;
    padding: 12px 24px;
    border:none;
    border-radius:4px;
    outline: none; 
    color:#FFFFFF;

}

.system-detail-sub-header-upload{
    background-color:#6D1DF0;
}

.system-detail-sub-header-inactive{
    background-color: #FF0D0D;
}

.disabledInactiveIcon{
    background-color: #FF0D0D;
    opacity: 0.2;
}

.system-detail-selection{
    display: flex;
    gap:32px;
}

.system-detail-selection a{
    color: #6D1DF0;
    text-decoration: none;
}