/* Beds Title */
.bed-title {
    position: absolute;
    width: 123px;
    height: 38px;
    left: 136px;
    top: 136px;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: -0.02em;
    
}

/* Filters and Actions Container */
.filters-and-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin: 20px 0;
    padding: 8px 16px;
    background-color: #fff;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

/* Filters */
.filters {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input-group select:hover,
.input-group select:focus {
    border-color: #6D1DF0;
    box-shadow: 0px 0px 6px rgba(109, 29, 240, 0.3);
}

/* Add Bed Button */
.add-bed-btn {
    width: auto;
    height: 48px;
    padding: 12px 16px;
    background-color: #6D1DF0;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.add-bed-btn:hover {
    background-color: #5611C9;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Table Section */
.react-table {
    margin-top: 20px;
    border: 1px solid rgba(224, 224, 224, 0.6);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    overflow-x: auto;
    width: 100%;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 999;
}

.modal-content {
    position: relative;
    background: white;
    width: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-width: 100%;
    box-sizing: border-box;
}

/* Input/Select Field Styles */
.input-group {
    position: relative;
    margin-bottom: 16px;
}

.input-group input,
.input-group select {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    background-color: #fff;
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
}

/* Success Popup */
.success-popup {
    display: flex;
    align-items: center;
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    margin-top: 10px;
}
