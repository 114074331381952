.machine-version-detail-sub-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.machine-version-details{
    padding-inline:1%;
    padding-block: 22px;
}

.machine-version-detail-sub-header p{
    font-size: 12px;
}

.machine-version-detail-sub-header p a {
    color: #6D1DF0;
    text-decoration: none;
    font-weight: 600;
}

.machine-version-detail-sub-header h2{
    font-size: 28px;
}

