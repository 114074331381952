.stage-header {
    /* background: red; */
    /* background: #DDDDDD; */
    width: 100%;
    height: 10%;
    min-height: 70px;
    margin-block:1rem;
    z-index: 10;
}

.stage-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90%;
    /* padding-inline: 2%; */
}

.stage-header-content h2{
    font-size: 28px;
}

.stage-header-btn {
    background: #6D1DF0;
    border-radius: 4px;
    padding:12px 24px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    outline: none;
    border:none;
    margin-left: 8px;
}

.disabledUpdateIcon{
    background: #6D1DF0;
    opacity: 0.2;
    border-radius: 4px;
    padding:12px 24px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    outline: none;
    border:none;
    margin-left: 8px;
}


