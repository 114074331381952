.sticky-header thead {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #f7f7f7;
}

.sticky-header .rc-table-container .rc-table-content .rc-table-thead {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}

.sticky-header .rc-table-content {
    border: none;
}

.sticky-header .rc-table-cell {
    border: none;
    border-bottom: 0.5px solid #CECEDE;
    font-size: 0.76rem;
}


.sticky-header .rc-table-row .rc-table-cell-row-hover {
    background-color: #DDDDDD;
}

.sticky-header .machine_serial_number_id_class {
    font-weight: 600;
}

/* .sticky-header .rc-table-thead .machine_serial_number_id_class,
.rc-table-thead .current_version_class,
.rc-table-thead .sent_version_class,
.rc-table-thead .ots_status_class,
.rc-table-thead .update_date_class,
.rc-table-thead .update_time_class {
    font-weight: 400;
    color: #8888AF;
} */

/* .sticky-header .rc-table-thead .previous_version_class,
.rc-table-thead .updated_version_class,
.rc-table-thead .software_version_class,
.rc-table-thead .firmware_version_class,
.rc-table-thead .os_build_class {
    font-weight: 400;
    color: #8888AF;
} */

.sticky-header .rc-table-thead .header_column{
    font-weight: 400;
    color: #8888AF;
}

.sticky-header .rc-table-tbody 
.machine_serial_number_id_class,
.current_version_class,
.sent_version_class,
.ots_status_class,
.update_date_class,
.update_time_class {
    color: #000000;
}

.sticky-header .rc-table-tbody 
.previous_version_class,
.updated_version_class,
.update_date_class,
.software_version_class, 
.firmware_version_class, 
.os_build_class{
    color: #000000;
}

.sticky-header .rc-table-tbody .rc-table-row {
    height: 64px;
}

.sticky-header .rc-table-tbody td {
    /* padding: 21px 0px; */
    /* padding: 0px; */
}

.sticky-header .rc-table-tbody .rc-table-cell.ots_status_class {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
}

.sticky-header .rc-table-tbody .rc-table-cell.ots_status_class div {
    /* background: #DFF6E4; */
    border-radius: 4px;
    /* color: #21A366; */
    font-weight: 600;
    padding: 4px 10px;
    width: 160px;
    text-align: center;
}