.message_notificatin_container {
    background-color: #DFF6E4;
    width: auto;
    height: 36px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 21px;
}

.message_notificatin_container>img {
    width: 15px;
    height: 15px;
    margin-inline-end: 10px;
}

.message_notificatin_container>p {
    font-size: 14px;
    color: #268D3D;
    backdrop-filter: blur(50px);
}

.message_notificatin_container> p span {
    font-weight: 600;
    margin-inline-end: 6px;
}

/* .message_notificatin_container > p span:first-child{
    color: #268D3D;
    font-weight: 600;
} */

/* .message_notificatin_container > p span:last-child{
    color: #268D3D;
    font-weight: 600;
} */

.message_notificatin_container img:last-of-type {
    width: 15px;
    height: 15px;
    margin-left: auto;
}