.text-align-left {
    text-align: left !important;
}


.ots-details-container {
    display: flex;
    flex-direction: column;
    padding-inline: 1%;
    height: 100%;
    overflow: hidden;
}

.machine-details-table {
    overflow: scroll;
    max-height: calc(100vh - 212px);
}

.checkbox_class input[type="checkbox"] {
    accent-color: #6D1DF0;
}

.search-input {
    padding: 10px 4px;
    border-radius: 8px;
    background-color: #6e1df02a;
}

.clickable {
    cursor: pointer;
    color: #6D1DF0;
    font-weight: 600;
    text-decoration: underline;
}

.time-input {
    padding: 10px 4px;
    border-radius: 8px;
    background-color: #6e1df02a;
    width: 4ch;
    text-align: right;
    margin: 0px 5px;
    -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.runtime {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.filterWraper {
    display: flex;
    align-items: center;
}

.location {
    font-size: 12px !important;
    max-width: 140px;
}