/* Machines Dashboard Container */
.machines-dashboard {
    display: flex;
    flex-direction: column;
    gap: 20px;
}



/* Section Title */
.machine-section h3 {
    font-size: 20px;
    font-weight: 600;
    width: 170px;
    height: 10px;
    left: 70px;
    top: -30px;
    margin-bottom: 10px;
    color: #333;
    position: relative;
    margin-left: 20px; /* Adjust this value as needed */
}

/* Add Button */
.add-machine-btn {
    height: 48px;
    position: relative;
    left: 699px;
    top: 50px;
    padding: 12px 16px;
    background-color: #6D1DF0;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.add-machine-btn:hover {
    background-color: #5611C9;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Machine Type Buttons Container */
.machine-type-buttons {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    gap: 12px; /* Add space between the buttons */
    margin: 20px 0; /* Add spacing above and below the button container */
}

/* Individual Button Styles */
.button {
    padding: 10px 20px;
    border: 2px solid transparent;
    border-radius: 6px;
    background-color: #f5f5f5;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Hover Effect */
.button:hover {
    background-color: #e0e0e0;
}

/* Active State for Selected Machine Type */
.button.active {
    background-color: #6D1DF0;
    color: #fff;
    border-color: #6D1DF0;
}


/* Table Section */
.react-table {
    margin-top: 20px;
    border: 1px solid rgba(224, 224, 224, 0.6);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    overflow-x: auto;
    width: 100%;
}

/* Success Popup */
.success-popup {
    display: flex;
    align-items: center;
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    margin-bottom: 20px;
}
