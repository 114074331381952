.inactive-system-version-details{
    padding-inline: 2%;
    background-color: #EAEAF1;
    position: relative;
    height: 100%;
}

.fixed_column{
    min-width: 200px;
}

.inactive-system-update-details-table{
    overflow: scroll;
    height: calc(100vh - 240px);
    width: calc(100vw - 180px);
    position: sticky;
    top:100%;
    left:2%;
}


.inactive-system-update-details-table .rc-table thead th.sticky_column {
    position: sticky;
    z-index: 4;
}

.inactive-system-update-details-table .rc-table thead th.sticky_column:nth-child(1){
    left: 0;
}

.inactive-system-update-details-table .rc-table thead th.sticky_column:nth-child(2){
    left: 200px;
}

.inactive-system-update-details-table .rc-table tbody td.sticky_column {
    position: sticky;
    z-index: 4;
}

.inactive-system-update-details-table .rc-table tbody td.sticky_column:nth-child(1){
    left: 0;
}

.inactive-system-update-details-table .rc-table tbody td.sticky_column:nth-child(2){
    left: 200px;
}