.system-version-details {
    padding-inline: 2%;
    background-color: #EAEAF1;
    position: relative;
    height: 100%;
}

.fixed_column {
    min-width: 200px;
}

.system-update-details-table {
    overflow: scroll;
    height: calc(100vh - 240px);
    width: calc(100vw - 180px);
    position: sticky;
    top: 100%;
    left: 2%;
}



.system-update-details-table .rc-table thead th.sticky_column {
    position: sticky;
    z-index: 5;
}

.system-update-details-table .rc-table thead th.sticky_column:nth-child(1) {
    left: 0;
}

.system-update-details-table .rc-table thead th.sticky_column:nth-child(2) {
    left: 37px;
}

.system-update-details-table .rc-table thead th.sticky_column:nth-child(3) {
    left: 237px;
}

.system-update-details-table .rc-table thead th.sticky_column:nth-child(4) {
    left: 437px;
}

.system-update-details-table .rc-table tbody td.sticky_column {
    position: sticky;
    z-index: 2;
}

.system-update-details-table .rc-table tbody td.sticky_column:nth-child(1) {
    left: 0;
}

.system-update-details-table .rc-table tbody td.sticky_column:nth-child(2) {
    left: 37px;
}

.system-update-details-table .rc-table tbody td.sticky_column:nth-child(3) {
    left: 237px;
}

.system-update-details-table .rc-table tbody td.sticky_column:nth-child(4) {
    position: sticky;
    left: 437px;
}

.ots_status_class {
    background-color: blue;
}

input[type='checkbox'] {
    accent-color: #6D1DF0;
}