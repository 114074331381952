.radio-group {
    /* display: flex;
    flex-direction: row;
    margin: 15px;
    margin-top: -7px;
    margin-bottom: 23px;
   */
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 1px solid #E0E0EB;
}
  
  .radio-label {
    font-size: 18px;
    color: black; /* Text color */
    margin: 12px 45px;
    cursor: pointer;
  }
  
  .radio-label input {
    margin-right: 10px;
  }
  
  .radio-label.selected input {
    accent-color: #6D1DF0; /* Change radio button color when selected */
  }

.full_screen_modal_container {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.full_screen_modal {
    background: #FFFFFF;
    border-radius: 8px;
    width: 30%;
    height: fit-content
}

.full_screen_modal form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding:24px;
    height: fit-content;
}

.full_screen_modal form > p{
    font-size: 20px;
    font-weight: 600;
}

.system_version_container{
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    /* gap:18px; */
    /* margin-block-start: 36px; */
}

.system_version_container > p {
    margin: 0;
    margin-inline-start: 2.4rem;
    /* padding-inline-start: 18px; */
    text-align: start;
}

.system_version_container_card{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:1rem;
}

.system_version_container div{
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; */
    text-align: start;
}

.system_version_container div h3{
    font-size: 16px;
    /* margin-block-start: 0px; */
}


.system_version_container div p{
    font-size: 16px;
    color:#666666;
    text-align: start;
    overflow: hidden;
}

.system_version_button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap:16px;
    margin-block-start: 36px;
}

.system_version_button button{
    font-size: 18px;
    padding:12px 24px;
    outline: none;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    width: 120px;
    height: 48px;
    font-weight: 600;
}

.system_version_button button:nth-child(1){
    color: #6D1DF0;
    background-color: #FFFFFF;
    border:1px solid #6D1DF0;

}
.system_version_button button:nth-child(2){
    color:#FFFFFF;
    background-color: #6D1DF0;
}

.scrollable-container {
    max-height: 300px; /* Adjust this as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 95%;
    
}
