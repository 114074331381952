.status-modal {
  position: absolute;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 300px;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}
.dropdown-selected {
  padding: 10px;
  border: #aaa 1px solid;
  border-radius: 4px;
}

.dropdown-modal ul {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-modal li {
  width: 96%;
  padding: 5px;
  cursor: pointer;
  font-weight: 600;
  border: #aaa 1px solid;
}

.dropdown-modal li:hover {
  background-color: #d4d7f9;
}

.pending {
  color: #b20000;
}

.acknowledged {
  color: #006600;
}

.form-group textarea {
  width: 94%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  height: 80px;
}

.modal-actions {
  display: flex;
  justify-content: center;
}

.cancel-button,
.save-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.cancel-button {
  background: transparent;
  color: #6200ee;
  border: 1px solid #6200ee;
  margin-right: 10px;
}

.save-button {
  background: #6200ee;
  color: white;
}

.redText {
  color: red;
}

.save-button:disabled {
  background: #aaa;
  cursor: not-allowed;
}
