.successful_upload_complete_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.successful_upload_complete_container div:first-child{
    width: 100%;
    display: flex;
    justify-content: space-between;    
    align-items: center;
}

.successful_upload_complete_container div:first-child p{
    text-align: start;
}

.successful_upload_complete_container div:last-child{
    filter: invert(18%) sepia(92%) saturate(3395%) hue-rotate(259deg) brightness(88%) contrast(116%);
    font-weight: 600;
};
.successful_upload_complete_container div:first-child > p{
    width: fit-content;
    font-size: 12px;
}
.file_details{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:20px;
}

.file_details div ul li:first-child{
    color:#6D1DF0;
}

.file_details div ul li:last-child{
    font-size:12px; 
    text-align: start;
    font-weight: 400;
}

.file_details div{
    max-width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap:4px;

}
/* 
.file_details div:last-child{
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap:4px;
} */

.file_details div img,ul{
    width: fit-content;
    margin: 0;
    padding: 0  ;
}

.file_details div img{
    width: 25px;
    height: 25px;
}

.file_details div ul li{
    list-style-type: none;
}