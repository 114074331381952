.progress_bar_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:20px;
    width: 100%;
}

.progress_bar_container div:first-child{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:10px;
}

.progress_bar_container div .version_details{
    /* width: 100%; */
    margin: 0;      
    font-size: 16px;
    text-align: start !important;
}

.progress_bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap:30px;
}

.progress{
    width: 100%;
    height: 6px;
    background-color: #E6E6E6;
    border: 2px solid #E6E6E6;
    border-radius: 10px;
}

.progress > span{
    /* width: 40%;  */
    height: 100%;
    border-radius: 10px;
    background-color: #6D1DF0 !important;
    display: block;
}

.progress_states{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size:14px;
}

.progress_states span:first-child{
    color:#808080;
}

.try_again_error{
    color:#6D1DF0;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.progress .error{
    background-color:#FF0D0D !important; 
}

.error_span_message{
    color: #FF0D0D;
}

.success_span_message{
    color: #268D3D;
}
.error_wrong_file{
    color:#FF0D0D;
    font-weight: 500;
}
.error_smile{
    color:#268D3D;
    font-weight: 700;
    rotate: 90deg;
}