.full_screen_modal_container {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.full_screen_modal {
    background: #FFFFFF;
    border-radius: 16px;
    width: 50%;
    height: fit-content;
}

.full_screen_modal form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    box-sizing: border-box;
}

.upload_version_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.radio-group {
    /* display: flex;
    flex-direction: row;
    margin: 15px;
    margin-top: -7px;
    margin-bottom: 23px;
   */
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 1px solid #E0E0EB;
}
  
  .radio-label {
    font-size: 18px;
    color: black; /* Text color */
    margin: 12px 45px;
    cursor: pointer;
  }
  
  .radio-label input {
    margin-right: 10px;
  }
  
  .radio-label.selected input {
    accent-color: #6D1DF0; /* Change radio button color when selected */
  }
  
.upload_version_section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 1px solid #E0E0EB;
    padding-block-end: 10px;
}


.upload_version_section_padding {
    padding-block-end: 40px;
    padding-inline-start: 16px;
}

.version_upload_button {
    font-size: 18px;
    color: #6D1DF0;
    font-weight: 600;
    padding: 11.5px 27px;
    border: 1px solid #6D1DF0;
    border-radius: 4px;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    background-color: #FFFFFF;
}

.version_upload_button_error{
    color:#FF0D0D;
    border: 1px solid #FF0D0D;
}

.version_details {
    width: 100%;
    height: auto;
}

.system_version_details_container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.system_version_details_container div {
    width: 100%;
}

.system_version_details_container div:nth-child(1) {
    position: relative;
}

.system_version_details_container div:nth-child(1) span {
    color: #8888AF;
    font-size: 14px;
    position: absolute;
    top: 4%;
    left: 6%;
}

.system_version_details_container div:nth-child(2) {
    position: relative;
}

.system_version_details_container div:nth-child(2) span {
    color: #8888AF;
    font-size: 14px;
    position: absolute;
    top: 4%;
    left: 6%;
}

.system_version_details_container div>input {
    width: 96%;
    min-height: 2rem;
    box-sizing: border-box;
    padding-inline: 16px;
    padding-block-start:1.4rem;
    padding-block-end: 0.8rem;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #CECEDE;
    font-size: 18px;
}

.version_details input {
    width: 100%;
    min-height: 4rem;
    box-sizing: border-box;
    padding-inline: 16px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #CECEDE;
    font-size: 18px;
}

.version_details textarea {
    width: 100%;
    height: 6rem;
    min-height: fit-content;
    max-height: fit-content;
    box-sizing: border-box;
    padding-inline: 16px;
    padding-block: 10px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #CECEDE;
    font-size: 18px;
}

.system_update_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 16px;
    margin-block-start: 36px;
}

.system_update_button button {
    font-size: 18px;
    padding: 12px 24px;
    outline: none;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    width: 120px;
    height: 48px;
    font-weight: 600;
}

.system_update_button button:nth-child(1) {
    color: #6D1DF0;
    background-color: #FFFFFF;
    border: 1px solid #6D1DF0;

}

.system_update_button button:nth-child(2) {
    color: #FFFFFF;
    background-color: #6D1DF0;
}

.upload_button_disable {
    opacity: 0.2;
}

.error_style {
    color: red;
    font-weight: 600;
    text-align: center;
    margin: auto;
}

.success_style {
    color: green;
    font-weight: 600;
    text-align: center;
    margin: auto;
    display: none;
}


.wrong_file_error{
    color:#FF0D0D;
}