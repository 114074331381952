.ota-management-main-container{
    position: relative;
}

.full-screen-system-update-modal{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
}

.hideOtaDetails{
    display: hidden;
}

.hideOtaUpload{
    display: hidden;
}

.ots-dashboard{
    display: flex;
    justify-content: space-between;
    align-items: top;
}

.ots-sidebar{
    width: 6%;
    position: relative;
    right: 0%;
}

.ots-container{
    width: 94%;
    height: 87vh;
}