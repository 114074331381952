.full_screen_modal_container {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.full_screen_modal {
    background: #FFFFFF;
    border-radius: 8px;
    width: 30%;
    height: fit-content
}

.full_screen_modal form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
}

.full_screen_modal form p:nth-child(1) {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    text-align: center;
}

.full_screen_modal form p:nth-child(2) {
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    text-align: center;
}

.system_update_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 16px;
    margin-block-start: 36px;
}

.system_update_button button {
    font-size: 18px;
    padding: 12px 24px;
    outline: none;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    width: 120px;
    height: 48px;
    font-weight: 600;
}

.system_update_button button:nth-child(1) {
    color: #6D1DF0;
    background-color: #FFFFFF;
    border: 1px solid #6D1DF0;

}

.system_update_button button:nth-child(2) {
    color: #FFFFFF;
    background-color: #6D1DF0;
}